import { FussyEventReference } from "~/components/home/page/index/lib";
import PcDiscoveryFeedItem from "~/components/top/discovery-feed-item/PcDiscoveryFeedItem";
import SpDiscoveryFeedItem from "~/components/top/discovery-feed-item/SpDiscoveryFeedItem";
import useIsSpMode from "~/lib/client/hooks/useIsSpMode";

const DiscoveryFeedItem = ({ item }: { item: FussyEventReference }) => {
  const isSpMode = useIsSpMode();
  if (typeof item === "undefined") return null;

  return isSpMode ? (
    <SpDiscoveryFeedItem item={item} />
  ) : (
    <PcDiscoveryFeedItem item={item} />
  );
};
export default DiscoveryFeedItem;
