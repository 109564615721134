import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Skeleton, Theme, useTheme } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { ReactNode } from "react";
import { match } from "ts-pattern";

import CategoryPanel from "~/components/common/category/CategoryPanel";
import { Props, categoryIds } from "~/components/home/page/index/lib";
import DiscoveryFeedItem from "~/components/top/discovery-feed-item";
import FussyAchievement from "~/components/top/fussy-achievement/FussyAchievement";
import JoinedMembershipContents, {
  MembershipContent,
} from "~/components/top/joined-membership-contents";
import TopContentFollowingCategories from "~/components/top/top-content-following-categories";
import Color, { engFont, fixedHeight, flexRow } from "~/lib/client/design";
import { useGraphqlClientWithAuth } from "~/lib/client/hooks/useGraphqlClient";
import "~/lib/monkeypatch";
import { useDiscoveryContext } from "~/pages";
import { currentUserLoadingStatus, useAppContext } from "~/pages/_app";

const MobilePage = () => {
  const theme = useTheme();
  const client = useGraphqlClientWithAuth();
  const {
    props: {
      categories,
      info,
      discoveryAds,
      fussyEventReferences,
      achievements,
    },
  } = useDiscoveryContext();
  const { currentUser } = useAppContext();

  return (
    <>
      {info && (
        <Box
          sx={{
            backgroundColor: Color.primary,
            color: "white",
            p: 1,
            width: "100%",
          }}
        >
          <Link href={info.url}>
            [{info.date} 更新] {info.title}
          </Link>
        </Box>
      )}
      <main css={rootStyle}>
        <div css={adSectionContainerStyle}>
          <section>
            <RowScrollContainer>
              {discoveryAds.chunk(2).map((ads) => {
                const [ad1, ad2] = ads;
                return (
                  <ChunkedItemsContainer height={100} gap={0.5} key={ad1.id}>
                    <AdBlock {...ad1} />
                    {ad2 && <AdBlock {...ad2} />}
                  </ChunkedItemsContainer>
                );
              })}
            </RowScrollContainer>
          </section>
        </div>

        <div css={spaceAfterAdStyle} />
        {match(currentUserLoadingStatus(currentUser))
          .with("loading", () => (
            <Skeleton variant={"rectangular"} width={"100%"} height={400} />
          ))
          .with("notLoggedIn", () => null)
          .with("loggedIn", () => null)
          .exhaustive()}
        {client && (
          <section>
            <h2>フォローしているFave</h2>
            <TopContentFollowingCategories client={client} />
          </section>
        )}
        {client && (
          <JoinedMembershipContents
            client={client}
            success={({ data }) =>
              data.userVisibleMembershipLimitedContents.length === 0 ? null : (
                <section>
                  <FollowableContentHeader>
                    <h2>メンバーシップへの新着</h2>
                  </FollowableContentHeader>
                  <div>
                    <MembershipContent data={data} />
                  </div>
                </section>
              )
            }
            pending={() => (
              <Skeleton variant={"rectangular"} width={"100%"} height={100} />
            )}
            error={() => null}
          />
        )}
        <section>
          <div>
            <h2 className={engFont.className}>Fave</h2>
            <Link href={`/category`} css={linkStyle(theme)}>
              すべてのFave↗
            </Link>
          </div>
          <RowScrollContainer>
            {categories
              .filter((category) => categoryIds.includes(category.id))
              .chunk(2)
              .map((twoCategories) => {
                const [category1, category2] = twoCategories;
                return (
                  <ChunkedItemsContainer
                    height={163}
                    gap={0.5}
                    key={category1.id}
                  >
                    {<CategoryPanel category={{ ...category1 }} />}
                    {category2 && <CategoryPanel category={category2} />}
                  </ChunkedItemsContainer>
                );
              })}
          </RowScrollContainer>
        </section>

        <section>
          <FollowableContentHeader>
            <h2 className={engFont.className}>Achievements</h2>
          </FollowableContentHeader>
          <RowScrollContainer>
            {achievements.map((achievement) => (
              <FussyAchievement
                achievement={achievement}
                key={achievement.id}
              />
            ))}
          </RowScrollContainer>
        </section>
        <section>
          <FollowableContentHeader>
            <h2 className={engFont.className}>Activity Feed</h2>
          </FollowableContentHeader>
          <DiscoveryFeedItemsContainer>
            {fussyEventReferences.map((item, index) => (
              <li key={index}>
                <DiscoveryFeedItem item={item} />
              </li>
            ))}
          </DiscoveryFeedItemsContainer>
        </section>
      </main>
    </>
  );
};

/**
 *
 * @param children
 * @param height px
 * @param gap rem
 * @constructor
 */
const ChunkedItemsContainer = ({
  children,
  height,
  gap,
}: {
  children: ReactNode;
  height: number;
  gap: number;
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
        gap: 0.5rem;
        align-items: center;
        ${fixedHeight((height * 2 + gap * 16).toString() + "px")}
      `}
    >
      {children}
    </div>
  );
};

const AdBlock = (discoveryAd: Props["discoveryAds"][0]) => {
  return (
    <Link href={`/ad/${discoveryAd.id}`}>
      <Image
        src={discoveryAd.image.url}
        alt={discoveryAd.title}
        width={200}
        height={100}
        style={{
          width: 200,
          height: 100,
        }}
        priority={true}
      />
    </Link>
  );
};

const rootStyle = css`
  padding: 0 1rem;
`;

const RowScrollContainer = styled.div`
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 16px;
  padding: 0.2rem 0 0.5rem 0;
  gap: 0.5rem;
`;

const FollowableContentHeader = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 1.5rem;
  }
`;

const FollowButtonGroup = styled.div`
  ${flexRow};
  gap: 0.5rem;
`;

// 90vh 以降は縦スクロール可能にする
const DiscoveryFeedItemsContainer = styled.ul`
  display: flex;
  flex-flow: column;
  gap: 0.2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: y mandatory;
  scroll-padding: 0 16px;
  padding: 0.2rem 0 0.5rem 0;
  height: 50vh;

  li {
    list-style-type: none;
  }
`;

const linkStyle = (them: Theme) => css`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  color: ${them.palette.text.primary};
  text-decoration: underline;
`;

const adSectionContainerStyle = css`
  width: calc(100% + 2rem); /* 親要素のpaddingをカバーするために幅を調整 */
  margin-left: -1rem; /* 親要素のpaddingを打ち消す */
  margin-right: -1rem; /* 親要素のpaddingを打ち消す */
  background: #eee;
  padding: 0.5rem 0.25rem 0.25rem;
`;

const spaceAfterAdStyle = css`
  margin-top: 2rem;
`;

export default MobilePage;
