import { TopContentQuery } from "~/lib/graphql/client";
import {
  Achievement,
  DiscoveryAd as MDiscoveryAd,
} from "~/lib/server/microcms";

const categoryIds = [
  6, // 櫻坂
  112, // バーバパパ
  160, // からたち
  79, // 金属バット
  191, // 天才ピアニスト
  192, // ぎょうぶ
  188, // わーすた
  9, // さらば青春の光
  190, // サスペンダーズ
  178, // ぽんぽこ
];

type Category = TopContentQuery["categories"][number];
type FussyEventReference = TopContentQuery["fussyEventReferences"][number];
type DiscoveryAd = MDiscoveryAd;

type Props = {
  categories: Category[];
  info: {
    title: string;
    url: string;
    date: string;
  } | null;
  fussyEventReferences: FussyEventReference[];
  discoveryAds: DiscoveryAd[];
  achievements: Achievement[];
};

export type { Category, Props, FussyEventReference, DiscoveryAd };
export { categoryIds };
