import { css } from "@emotion/react";
import { Box, Skeleton } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { FaExternalLinkAlt } from "react-icons/fa";

import CategoryPanel from "~/components/common/category/CategoryPanel";
import { categoryIds } from "~/components/home/page/index/lib";
import DiscoveryFeedItem from "~/components/top/discovery-feed-item";
import FussyAchievement from "~/components/top/fussy-achievement/FussyAchievement";
import JoinedMembershipContents, {
  MembershipContent,
} from "~/components/top/joined-membership-contents";
import TopContentFollowingCategories from "~/components/top/top-content-following-categories";
import Color, { flexColumn, flexRow } from "~/lib/client/design";
import { useGraphqlClientWithAuth } from "~/lib/client/hooks/useGraphqlClient";
import { useDiscoveryContext } from "~/pages";
import { useAppContext } from "~/pages/_app";

const PcPage = () => {
  const {
    props: {
      categories,
      fussyEventReferences,
      discoveryAds,
      info,
      achievements,
    },
  } = useDiscoveryContext();
  const client = useGraphqlClientWithAuth();
  const { currentUser } = useAppContext();

  return (
    <main css={rootStyle}>
      {info && (
        <Box sx={infoLinkContainerStyle}>
          <Link href={info.url}>
            [{info.date} 更新] {info.title}
          </Link>
        </Box>
      )}
      <section css={sectionStyle}>
        {discoveryAds.length !== 0 && (
          <div css={discoveryAdListContainerStyle}>
            {discoveryAds.map((ad) => (
              <Link href={`/ad/${ad.id}`} key={ad.id}>
                <Image
                  src={ad.image.url}
                  alt={"広告の画像"}
                  width={280}
                  height={140}
                  key={ad.id}
                  style={{
                    width: 280,
                    height: 140,
                  }}
                  priority={true}
                />
              </Link>
            ))}
          </div>
        )}
      </section>
      <section css={sectionStyle}>
        {currentUser === undefined && (
          <Skeleton variant={"rounded"} width={"100%"} height={400} />
        )}
      </section>
      {client && (
        <section css={sectionStyle}>
          <h2>フォローしているFave</h2>
          <div css={faveSectionPanelStyle}>
            <TopContentFollowingCategories client={client} />
          </div>
        </section>
      )}
      {client && (
        <JoinedMembershipContents
          client={client}
          success={({ data }) =>
            data.userVisibleMembershipLimitedContents.length === 0 ? null : (
              <section css={sectionStyle}>
                <h2>メンバーシップへの新着</h2>
                <MembershipContent data={data} />
              </section>
            )
          }
          pending={() => (
            <Skeleton variant={"rectangular"} width={"100%"} height={100} />
          )}
          error={() => null}
        />
      )}
      <section css={sectionStyle}>
        <div css={h2AndLabelStyle}>
          <h2>Fave</h2>
          <Link href={"/category"} css={categoryLinkStyle}>
            すべてのFave
            <FaExternalLinkAlt />
          </Link>
        </div>
        <ul css={faveSectionPanelStyle}>
          {categories
            .filter((category) => categoryIds.includes(category.id))
            .chunk(2)
            .map((chunkedCategories) => {
              const [first, second] = chunkedCategories;
              return (
                <div
                  css={categoryPanelsContainerStyle}
                  key={`${first.id}-${second.id}`}
                >
                  <li key={first.id}>
                    <CategoryPanel category={first} />
                  </li>
                  <li key={second.id}>
                    <CategoryPanel category={second} />
                  </li>
                </div>
              );
            })}
        </ul>
      </section>

      <section css={sectionStyle}>
        <h2>Achievements</h2>
        <ul css={achievementListContainerStyle}>
          {achievements.map((achievement) => (
            <FussyAchievement achievement={achievement} key={achievement.id} />
          ))}
        </ul>
      </section>

      <section css={sectionStyle}>
        <h2>Activity Feed</h2>
        <ul css={[feedSectionPanelStyle, flexColumn]}>
          {fussyEventReferences.map((item, index) => (
            <li key={index}>
              <DiscoveryFeedItem item={item} />
            </li>
          ))}
        </ul>
      </section>
    </main>
  );
};

const rootStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
`;

const sectionStyle = css`
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 2rem;

  h2 {
    margin: 0;
    font-size: 2rem;
  }
`;

const h2AndLabelStyle = css`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
`;

const infoLinkContainerStyle = {
  backgroundColor: Color.primary,
  color: "white",
  p: 2,
  pl: "4rem",
  width: "100%",
};

const discoveryAdListContainerStyle = css`
  ${flexRow};
  flex-wrap: wrap;
  gap: 1.5rem;
`;

const faveSectionPanelStyle = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  overflow-x: auto;

  padding: 1rem;
  list-style-type: none;
`;

const categoryPanelsContainerStyle = css`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

const achievementListContainerStyle = css`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  overflow-x: auto;
  list-style-type: none;
  padding-bottom: 1.5rem;
`;

const feedSectionPanelStyle = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
  list-style-type: none;
  height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  justify-content: flex-start;
`;

const categoryLinkStyle = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: ${Color.primary};
`;

export default PcPage;
