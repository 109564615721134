import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import Image from "next/image";
import Link from "next/link";

import { mediaQueryIsMobile } from "~/lib/client/design";
import { formatDate } from "~/lib/graphql/graphqlClient";
import { Achievement } from "~/lib/server/microcms";

const FussyAchievement = ({ achievement }: { achievement: Achievement }) => {
  const theme = useTheme();
  return (
    <div css={rootStyle}>
      <Link href={`/achievement/${achievement.id}`}>
        <Image
          src={achievement.imageUrl.url}
          alt={achievement.title}
          width={280}
          height={350}
          css={imageStyle}
        />
        <div css={footerStyle(theme)}>
          <h3 id={"achievementTitle"}>{achievement.title}</h3>
          <span id={"achievementCategory"}>{achievement.categoryName}</span>
          <span id={"achievementPublishedAt"}>
            {formatDate(achievement.publishedAt)}
          </span>
        </div>
      </Link>
    </div>
  );
};

const rootStyle = css`
  width: 280px;
  border-radius: 0.75rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  ${mediaQueryIsMobile(css`
    width: 200px;
  `)}
`;

const imageStyle = css`
  border-radius: 0.75rem 0.75rem 0 0;

  ${mediaQueryIsMobile(css`
    height: 250px;
    width: 200px;
  `)}
`;

const footerStyle = (theme: Theme) => css`
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;

  ${mediaQueryIsMobile(css`
    padding: 0.5rem;
  `)}

  #achievementTitle {
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem 0;

    ${mediaQueryIsMobile(css`
      font-size: 0.75rem;
      margin: 0.25rem 0;
    `)}
  }

  #achievementCategory {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    color: ${theme.palette.primary.main};
  }

  #achievementPublishedAt {
    display: block;
    font-size: 0.75rem;
    color: ${theme.palette.text.secondary};
  }
`;

export default FussyAchievement;
