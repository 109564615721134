import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Theme, useTheme } from "@mui/material";
import Link from "next/link";

import { FussyEventReference } from "~/components/home/page/index/lib";
import {
  Icon,
  buildMessage,
  eventGenreColor,
  eventResourceTypeLabel,
} from "~/components/top/discovery-feed-item/lib";
import { fixedWidth, flexRow } from "~/lib/client/design";

const SpDiscoveryFeedItem = ({ item }: { item: FussyEventReference }) => {
  const theme = useTheme();
  const message = buildMessage(item);
  const genreColor = eventGenreColor(item, theme);

  return (
    <div css={rootStyle(theme)}>
      <LeftCategoryContainer muiTheme={theme}>
        <p
          css={css`
            display: flex;
            justify-content: center;
            padding: 0.2rem;
            font-size: 12px;
            font-weight: 600;
          `}
        >
          {item.category.name}
        </p>
        <hr color={genreColor} />
        <div
          css={css`
            ${flexRow};
            gap: 0.25rem;
            align-items: center;
            color: ${genreColor};
            justify-content: center;
          `}
        >
          <Icon item={item} />
          <span css={css``}>{eventResourceTypeLabel(item)}</span>
        </div>
      </LeftCategoryContainer>
      <RightFeedItemContainer>
        <span>{message.firstText}</span>
        <Link href={message.link.href} css={linkStyle(theme)}>
          {message.link.text}
        </Link>
        <span>{message.lastText}</span>
      </RightFeedItemContainer>
    </div>
  );
};

const rootStyle = (theme: Theme) => css`
  ${flexRow};
  align-items: flex-start;
  gap: 1rem;
  flex: 1;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${theme.palette.divider};
`;

const RightFeedItemContainer = styled.div`
  span {
    font-size: 12px;
  }

  a {
    font-size: 12px;
  }
`;

const LeftCategoryContainer = styled.div<{ muiTheme: Theme }>`
  ${fixedWidth("7.25rem")};
  min-height: 4.5rem;
  background: ${(props) => props.muiTheme.palette.background.paper};
  border-radius: 0.5rem;
`;

const linkStyle = (theme: Theme) => css`
  text-decoration: underline;
  color: ${theme.palette.text.primary};
`;

/**
 * @package
 */
export default SpDiscoveryFeedItem;
