import { css } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { match } from "ts-pattern";

import CategoryPanel from "~/components/common/category/CategoryPanel";
import { size as pcCategoryPanelSize } from "~/components/common/category/CategoryPanel/PcCategoryPanel";
import { size as spCategoryPanelSize } from "~/components/common/category/CategoryPanel/SpCategoryPanel";
import { mediaQueryIsDesktop, mediaQueryIsMobile } from "~/lib/client/design";
import { Sdk } from "~/lib/graphql/client";

function useTopContentFollowingCategories(client: Sdk) {
  return useQuery({
    queryKey: ["topContentFollowingCategories"],
    queryFn: () => client.topContentFollowingCategories(),
  });
}

const TopContentFollowingCategories = ({ client }: { client: Sdk }) => {
  const result = useTopContentFollowingCategories(client);

  return (
    <div css={rootStyle}>
      {match(result)
        .with({ status: "success" }, ({ data }) => {
          if (data.me.followingCategories.length === 0) {
            return (
              <p css={noContentStyle}>
                応援しているFaveを見つけてフォローしよう！
              </p>
            );
          } else {
            return data.me.followingCategories.map((category) => (
              <CategoryPanel key={category.id} category={category} />
            ));
          }
        })
        .with({ status: "pending" }, () => null)
        .with({ status: "error" }, () => <p>エラーが発生しました</p>)
        .exhaustive()}
    </div>
  );
};

const rootStyle = css`
  width: 100%;

  display: flex;
  flex-flow: row;
  gap: 0.5rem;
  overflow-y: scroll;

  ${mediaQueryIsDesktop(css`
    height: ${pcCategoryPanelSize.height + 16}px;
  `)};

  ${mediaQueryIsMobile(css`
    height: ${spCategoryPanelSize.height + 8}px;
  `)};
`;

const noContentStyle = css`
  width: 100%;
  background: #eee;
  color: #666;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: 1rem;
`;

export default TopContentFollowingCategories;
