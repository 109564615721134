import { css } from "@emotion/react";
import {
  Card,
  CardHeader,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import { FC } from "react";
import { match } from "ts-pattern";

import { mediaQueryIsMobile } from "~/lib/client/design";
import { SP_MODE_WIDTH } from "~/lib/client/hooks/useIsSpMode";
import {
  Sdk,
  TopContentJoinedMembershipContentsQuery,
} from "~/lib/graphql/client";
import { formatDate } from "~/lib/graphql/graphqlClient";

function useQueryWithComponent<Data>(
  queryKey: string[],
  queryFn: () => Promise<Data>,
  success: FC<{ data: Data }>,
  pending: FC,
  error: FC
) {
  const result = useQuery({ queryKey, queryFn });
  return match(result)
    .with({ status: "success" }, success)
    .with({ status: "pending" }, pending)
    .with({ status: "error" }, error)
    .exhaustive();
}

const JoinedMembershipContents = ({
  client,
  success,
  pending,
  error,
}: {
  client: Sdk;
  success: FC<{ data: TopContentJoinedMembershipContentsQuery }>;
  pending: FC;
  error: FC;
}) => {
  return useQueryWithComponent<TopContentJoinedMembershipContentsQuery>(
    ["topContentJoinedMembershipContents"],
    () => client.topContentJoinedMembershipContents(),
    success,
    pending,
    error
  );
};

function extractCategoryAndMembershipForComponent(
  content: TopContentJoinedMembershipContentsQuery["userVisibleMembershipLimitedContents"][number]
) {
  // 最も高いメンバーシップのIDを取得
  const membership = content.visiblePartnerCategoryMemberships.toSorted(
    (a, b) => b.pricePerMonth - a.pricePerMonth
  )[0];
  const category = membership.category;
  return { category, membership };
}

const MembershipContent = ({
  data,
}: {
  data: TopContentJoinedMembershipContentsQuery;
}) => {
  const theme = useTheme();
  const isSp = useMediaQuery(`(max-width: ${SP_MODE_WIDTH}px)`);

  return (
    <div css={contentsContainerStyle}>
      {data.userVisibleMembershipLimitedContents
        .toSorted((a, b) => b.createdAt.localeCompare(a.createdAt))
        .map((content) => {
          const { category, membership } =
            extractCategoryAndMembershipForComponent(content);
          return (
            <div key={content.id}>
              <Link
                href={`/category/${category.id}/membership/${membership.id}`}
              >
                <Card sx={cardStyle(theme, isSp)}>
                  <CardHeader
                    title={content.title}
                    subheader={category.name}
                    subheaderTypographyProps={{ variant: "body2", mt: 1 }}
                    sx={{ paddingBottom: 0 }}
                    titleTypographyProps={{
                      variant: "h6",
                      fontSize: isSp ? "1rem" : "1.2rem",
                    }}
                  />
                  <div>
                    <div css={viewDetailStyle(theme)}>
                      <span>詳細を見る</span>
                    </div>
                    <div css={metaInfoStyle(theme)}>
                      <p>投稿日: {formatDate(content.createdAt)}</p>
                    </div>
                  </div>
                </Card>
              </Link>
            </div>
          );
        })}
    </div>
  );
};

const contentsContainerStyle = css`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: row;
  gap: 1rem;
  overflow-x: auto;

  ${mediaQueryIsMobile(css`
    padding: 0.5rem;
  `)};
`;

const cardStyle = (theme: Theme, isSpMode: boolean) =>
  isSpMode
    ? {
        width: "14rem",
        minHeight: "300px",
        backgroundColor: theme.palette.background.default,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }
    : {
        width: "275px",
        minHeight: "300px",
        backgroundColor: theme.palette.background.default,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      };

const viewDetailStyle = (theme: Theme) => css`
  display: flex;
  justify-content: flex-end;
  color: ${theme.palette.primary.main};
  padding: 1rem;
`;

const metaInfoStyle = (theme: Theme) => css`
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 0.5rem;
`;

export { JoinedMembershipContents, MembershipContent };
export default JoinedMembershipContents;
