import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Theme } from "@mui/material";
import { P, match } from "ts-pattern";

import { FussyEventReference } from "~/components/home/page/index/lib";
import { FussyEventEnum } from "~/lib/graphql/client";

type Message = {
  firstText: string;
  link: {
    href: string;
    text: string;
  };
  lastText: string;
};

const Icon = ({ item }: { item: FussyEventReference }) => {
  const SelectedIcon = match(item)
    .with(
      P.union(
        { fussyEventType: FussyEventEnum.CreateCategory },
        { fussyEventType: FussyEventEnum.CreateForm },
        { fussyEventType: FussyEventEnum.CreateQuest }
      ),
      () => <CheckCircleOutlineOutlinedIcon />
    )
    .with(
      P.union(
        { fussyEventType: FussyEventEnum.CompleteQuest },
        { fussyEventType: FussyEventEnum.CreateResponse }
      ),
      () => <CampaignOutlinedIcon />
    )
    .exhaustive();

  return <div>{SelectedIcon}</div>;
};

function buildMessage(item: FussyEventReference) {
  return match<FussyEventReference, Message>(item)
    .with(
      {
        fussyEventType: FussyEventEnum.CreateCategory,
        eventable: { __typename: "Category" },
      },
      (item) => ({
        firstText: `新しいFave`,
        link: {
          href: `/category/${item.eventable.id}`,
          text: `「${item.eventable.name}」`,
        },
        lastText: `が作成されました`,
      })
    )
    .with(
      {
        fussyEventType: FussyEventEnum.CreateForm,
        eventable: { __typename: "Form" },
      },
      (item) => ({
        firstText: `データベース`,
        link: {
          href: `/database/${item.eventable.id}`,
          text: `「${item.eventable.title}」`,
        },
        lastText: `が作成されました`,
      })
    )
    .with(
      {
        fussyEventType: FussyEventEnum.CreateQuest,
        eventable: { __typename: "Quest" },
      },
      (item) => ({
        firstText: `クエスト`,
        link: {
          href: `/quest/${item.eventable.id}`,
          text: `「${item.eventable.title}」`,
        },
        lastText: `が作成されました`,
      })
    )
    .with(
      {
        fussyEventType: FussyEventEnum.CreateResponse,
        eventable: { __typename: "Form" },
      },
      (item) => ({
        firstText: `データベース`,
        link: {
          href: `/database/${item.eventable.id}`,
          text: `「${item.eventable.title}」`,
        },
        lastText: `に新しい回答が投稿されました`,
      })
    )
    .with(
      {
        fussyEventType: FussyEventEnum.CompleteQuest,
        eventable: { __typename: "Quest" },
      },
      (item) => ({
        firstText: `クエスト`,
        link: {
          href: `/quest/${item.eventable.id}`,
          text: `「${item.eventable.title}」`,
        },
        lastText: `が完了しました`,
      })
    )
    .otherwise((item) => ({
      firstText: "",
      link: { href: "", text: "" },
      lastText: "",
    }));
}

const eventResourceTypeLabel = (item: FussyEventReference) =>
  match(item.eventable)
    .with({ __typename: "Category" }, () => "Fave")
    .with({ __typename: "Form" }, () => "Database")
    .with({ __typename: "Quest" }, () => "Quest")
    .exhaustive();

const eventGenreColor = (item: FussyEventReference, theme: Theme) =>
  match<FussyEventEnum>(item.fussyEventType) // FussyEventEnum を分けた区分を一旦 eventGenre と呼ぶ
    .with(
      P.union(FussyEventEnum.CompleteQuest, FussyEventEnum.CreateResponse),
      () => theme.palette.primary.main
    )
    .with(
      P.union(
        FussyEventEnum.CreateCategory,
        FussyEventEnum.CreateForm,
        FussyEventEnum.CreateQuest
      ),
      () => theme.palette.secondary.main
    )
    .exhaustive();

export { Icon, buildMessage, eventResourceTypeLabel, eventGenreColor };
export type { Message };
