import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import Link from "next/link";

import { FussyEventReference } from "~/components/home/page/index/lib";
import {
  Icon,
  buildMessage,
  eventGenreColor,
  eventResourceTypeLabel,
} from "~/components/top/discovery-feed-item/lib";
import { fixedWidth, flexRow } from "~/lib/client/design";

const PcDiscoveryFeedItem = ({ item }: { item: FussyEventReference }) => {
  const theme = useTheme();
  const message = buildMessage(item);

  return (
    <div css={rootStyle}>
      <Link
        css={categoryEventStyle(theme)}
        href={`/category/${item.category.id}`}
      >
        <span css={categoryNameStyle(eventGenreColor(item, theme))}>
          {item.category.name}
        </span>
        <div css={iconLabelStyle(eventGenreColor(item, theme))}>
          <Icon item={item} />
          <span>{eventResourceTypeLabel(item)}</span>
        </div>
      </Link>
      <div css={messageStyle(theme)}>
        <span>{message.firstText}</span>
        <Link href={message.link.href} css={linkStyle(theme)}>
          {message.link.text}
        </Link>
        <span>{message.lastText}</span>
      </div>
    </div>
  );
};

const rootStyle = css`
  ${flexRow};
  align-items: center;
  gap: 1rem;
  flex: 1;
`;

const categoryEventStyle = (theme: Theme) => css`
  ${flexRow};
  align-items: center;
  justify-content: center;
  ${fixedWidth("17.5rem")};
  background: ${theme.palette.background.paper};
  border-radius: 0.5rem;
  font-weight: 600;
`;

const categoryNameStyle = (eventGenreColor: string) => css`
  ${fixedWidth("9rem")};
  height: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-right: 1px solid ${eventGenreColor};
`;

const iconLabelStyle = (eventGenreColor: string) => css`
  ${flexRow};
  color: ${eventGenreColor};
  padding-left: 0.5rem;
  gap: 0.25rem;
`;

const messageStyle = (theme: Theme) => css`
  padding-top: 1.5rem;
  padding-left: 0.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${theme.palette.divider};
  flex: 1;
`;

const linkStyle = (theme: Theme) => css`
  text-decoration: underline;
  color: ${theme.palette.text.primary};
`;

/**
 * @package
 */
export default PcDiscoveryFeedItem;
